import React from 'react';
import { Container } from './footer.css';

const Footer = () => (
  <Container>
    © 2022. All rights reserved.
  </Container>
);

export default Footer;
